const supplyId = (function() {
  let sup = 0;
  return () => {
    const seed = sup;
    sup = sup === Number.MAX_SAFE_INTEGER ? 0 : sup + 1;
    return `veb2023-tinymce-connector-${seed}`;
  };
}());

class Interop {
  constructor(readonly dotNetRef: DotNetRef) { }

  getValue(): Promise<string> {
    console.log('getValue');
    return this.dotNetRef.invokeMethodAsync<string>('JsGetValueAsync');
  }

  updateValue(value: string): Promise<void> {
    console.log('updateValue', value);
    return this.dotNetRef.invokeMethodAsync<void>('JsUpdateValueAsync', value);
  }

  initialize(id: string) {
    console.log('initialize', id);
    return this.dotNetRef.invokeMethodAsync<void>('JsInitializeAsync', id);
  }

  ready(id: string) {
    console.log('ready', id);
    return this.dotNetRef.invokeMethodAsync<void>('JsReadyAsync', id);
  }

  notify(eventName: string) {
    console.log('notify', eventName);
    return this.dotNetRef.invokeMethodAsync<void>('JsNotifyAsync', eventName);
  }
}

window.veb2023 = window.veb2023 || {};
window.veb2023.tinyMce = {
  applyBold(id: string) {
    const ed = window.tinymce.get(id);
    if (!ed) {
      throw new Error(`invalid editor id ${id}`);
    }
    ed.execCommand('bold', false);
  },
  applyItalic(id: string) {
    const ed = window.tinymce.get(id);
    if (!ed) {
      throw new Error(`invalid editor id ${id}`);
    }
    ed.execCommand('italic', false);
  },
  applyLink(id: string) {
    const ed = window.tinymce.get(id);
    if (!ed) {
      throw new Error(`invalid editor id ${id}`);
    }
    ed.execCommand('mceLink', false);
  },
  applyUnlink(id: string) {
    const ed = window.tinymce.get(id);
    if (!ed) {
      throw new Error(`invalid editor id ${id}`);
    }
    ed.execCommand('unlink', false);
  },
  applyList(id: string) {
    const ed = window.tinymce.get(id);
    if (!ed) {
      throw new Error(`invalid editor id ${id}`);
    }
    ed.execCommand('InsertUnorderedList', false, { });
  },
  async updateValue(id: string, value: string) {
    const ed = window.tinymce.get(id);
    if (!ed) {
      throw new Error(`invalid editor id ${id}`);
    }
    ed.setContent(value, { format: 'html' });
  },
  init: (options: Record<string, any>, dotNetRef: DotNetRef): Promise<void> => {
    // helper
    const interop = new Interop(dotNetRef);
    // preprocess values
    let id: string|null = null;
    const handlers: Record<string, boolean> = {};
    const opts: Record<string, any> = {};
    for (const key of Object.keys(options)) {
        const value = options[key];
        // eliminate null values
        if (null === value || undefined === value) {
          continue;
        }
        // handle special cases
        if ('target' === key) {
          if (value instanceof HTMLElement) {
            if (!value.id) {
              value.id = supplyId();
            }
            id = value.id;
          } else {
            throw new Error('target must be a valid html element');
          }
        }
        if ('events' === key) {
          for (const name of Object.keys(value)) {
            const hx = value[name];
            if (hx) {
              handlers[name] = !!(handlers[name] || hx);
            }
          }
          continue;
        }
        if ('init_instance_callback' === key) {
          opts[key] = (ed: TinyMCEEditor) => {
            interop.initialize(ed.id);
          };
          continue;
        }
        if ('menu' === key || 'valid_classes' === key || 'valid_styles' === key) {
          if (!Object.keys(value).length) {
            continue;
          }
        }
        // passthrough
        opts[key] = value;
    }
    // handlers
    opts.setup = (ed: TinyMCEEditor) => {
      interop.ready(ed.id); // NOTE: no await
      ed.on('init', async () => {
        if (!opts.init_instance_callback) {
          await interop.initialize(ed.id);
        }
        const value = await interop.getValue();
        ed.setContent(value || '', { format: 'html' });
      });
      ed.on('change input undo redo', async () => {
        const currentValue = ed.getContent({ format: 'html' });
        await interop.updateValue(currentValue);
      });
      if (Object.keys(handlers).length) {
        for (const evt of Object.keys(handlers).filter(key => handlers[key])) {
          ed.on(evt, async () => {
            interop.notify(evt);
          });
        }
      }
    };
    console.warn(opts);
    window.tinymce.init(opts);
    return Promise.resolve();
  },
  async destroy(id: string): Promise<void> {
    const ed = window.tinymce.get(id);
    if (ed) {
      ed.destroy();
      const el = document.getElementById(id);
      if (el) {
        el.id = '';
      }
    }
  },
  check(id: string) {
    const ed = window.tinymce.get(id);
    return !!(ed);
  },
  updateBtnStates: (highlightBtn: Element, quoteBtn: Element, hintBtn: Element, highlight: boolean, quote: boolean, hint: boolean) => {
    const updateActive = (btn: Element, value: boolean) => {
      if (btn instanceof Element) {
        if (value) {
          btn.setAttribute('active', '');
        } else {
          btn.removeAttribute('active');
        }
      }
    };
    updateActive(highlightBtn, highlight);
    updateActive(quoteBtn, quote);
    updateActive(hintBtn, hint);
  }
};